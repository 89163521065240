<template>
  <v-card
    class="white"
    elevation="1"
    fluid
    v-if="getRole !== null && activeTab"
  >
    <v-card-title>
      {{ activeTab.name.toUpperCase() }} - {{ getSelectedUser[0].name }} -
      {{ getSelectedUser[0].uid }} - {{ getSelectedUser[0].email }}
      <v-spacer></v-spacer>
      <v-btn v-if="getRole && getRole.id == hradmin" @click="goToCreateCard">
        Create Card
      </v-btn>
    </v-card-title>
    <v-divider />
    <template v-if="activeTab.name == 'handover'">
      <v-sheet elevation="0" width="500px" class="ma-10">
        <h4>Select My Card</h4>
        <v-list two-line>
          <template v-if="myCards.length">
            <v-list-item-group
              v-model="selectedMyCard"
              active-class="blue--text"
            >
              <template v-for="(card, index) in myCards">
                <v-list-item >
                  <template v-slot:default="{ active }">
                    <v-list-item-content :key="index">
                      <v-img
                        v-if="card.screenshotPath"
                        max-width="384"
                        max-height="240"
                        elevation="1"
                        style="
                          border-radius: 10px;
                          border: 0.5px solid grey;
                          margin-bottom: 20px;
                        "
                        :src="card.screenshotPath"
                      >
                      </v-img>
                      <v-list-item-title>
                        {{card.name}}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                      >
                      {{
                        `${card.id} || ${
                            card.isPrivate ? 'Private' : 'Open'
                          } || Chat Enable: ${card.enableChat}`
                      }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle>
                      {{
                        `${
                            card.designation ? `${card.designation} || ` : ''
                          }${card.emails ? card.emails.join(',') : ''}`
                      }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon v-if="active" x-small color="blue">
                        fa-check
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
            <v-divider />
          </template>
        </v-list>
      </v-sheet>
      <v-sheet elevation="0" width="500px" class="ma-10">
        <v-autocomplete
          v-model="handoverUserUID"
          :items="
            getUsers.filter(
              (user) =>
                user.uid != getSelectedUser[0].uid &&
                user.companyId &&
                user.companyId == getSelectedUser[0].companyId
            )
          "
          clearable
          :hint="
            handoverUserUID
              ? `From ${
                  getSelectedUser[0].name
                } will be transfer all shared, received cards and chatrooms To ${
                  getUsers.find((user) => user.uid == handoverUserUID).name
                }`
              : ''
          "
          persistent-hint
          :item-text="(user) => `${user.name} - ${user.email} - ${user.uid}`"
          item-value="uid"
          label="Select user to handover."
          regular
        ></v-autocomplete>
        <template v-if="handoverUserUID">
          <h4 class="mt-10">Select Card</h4>
          <v-list two-line>
            <template v-if="cards.length">
              <v-list-item-group
                v-model="selectedCard"
                active-class="blue--text"
              >
                <template v-for="(card, index) in cards">
                  <v-list-item >
                    <template v-slot:default="{ active }">
                      <v-list-item-content :key="index">
                        <v-img
                          v-if="card.screenshotPath"
                          max-width="384"
                          max-height="240"
                          elevation="1"
                          style="
                            border-radius: 10px;
                            border: 0.5px solid grey;
                            margin-bottom: 20px;
                          "
                          :src="card.screenshotPath"
                        >
                        </v-img>
                        <v-list-item-title>
                          {{card.name}}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                        >
                        {{
                          `${card.id} || ${
                              card.isPrivate ? 'Private' : 'Open'
                            } || Chat Enable: ${card.enableChat}`
                        }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          {{
                            `${
                              card.designation ? `${card.designation} || ` : ''
                            }${card.emails ? card.emails.join(',') : ''}`
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon v-if="active" x-small color="blue">
                          fa-check
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
              <v-divider />
            </template>
          </v-list>
          <v-progress-circular
            v-if="loading"
            class="my-10"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else-if="
              handoverUserUID &&
              selectedMyCard !== null &&
              selectedMyCard !== undefined &&
              selectedCard !== null &&
              selectedCard !== undefined
            "
            class="my-10"
            color="primary"
            @click="transferAllData"
          >
            Transfer All Data
          </v-btn>
        </template>
      </v-sheet>
    </template>
    <vue-good-table
      v-else
      ref="collectionTable"
      :columns="getHeaders"
      :rows="getCollectionResults"
      :line-numbers="true"
      styleClass="vgt-table striped"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 20, 50, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        jumpFirstOrLast: false,
        firstLabel: 'First',
        lastLabel: 'Last',
        nextLabel: 'Next',
        prevLabel: 'Prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
        infoFn: (params) =>
          `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
      }"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        placeholder: 'Search',
      }"
      @on-row-click="handleClick"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'date'">
          <div>{{ formatDate(props.row) }}</div>
        </span>
        <span v-else-if="props.column.field == 'shared_card'" @click.stop>
          <v-btn
            v-if="
              props.row.acceptOnCardId && props.row.acceptOnCardId.length == 0
            "
            @click="
              {
                shareCardOnReceivedCardDialog = true;
                rowReceivedCard = props.row;
                rowReceivedCardIndex = props.index;
                fetchCard(props.row.cardId).then(
                  (cardData) => (receivedCardData = cardData)
                );
              }
            "
            >Share Card
          </v-btn>
          <div v-else-if="props.row.acceptOnCardId">
            {{ props.row.acceptOnCardId.join(' , ') }}
          </div>
        </span>
        <span v-else-if="props.column.field == 'card_action'" @click.stop>
          <v-icon
            v-if="getRole && getRole.id == hradmin"
            small
            @click="editCard(props)"
          >
            fa-pen-square
          </v-icon>
        </span>
        <span v-else-if="props.column.field == 'emails'" @click.stop>
          {{ props.row.emails.length > 0 ? props.row.emails[0] : '' }}
        </span>
        <span v-else-if="props.column.field == 'address'" @click.stop>
          {{ props.row.address.length > 0 ? props.row.address[0].address : '' }}
        </span>
        <span v-else-if="props.column.field == 'phoneNumbers'" @click.stop>
          {{
            props.row.phoneNumbers.length > 0
              ? props.row.phoneNumbers[0].phone
              : ''
          }}
        </span>
      </template>
    </vue-good-table>
    <v-dialog height="600" width="500" v-model="shareCardOnReceivedCardDialog">
      <v-card style="padding: 20px">
        <h4 class="pa-20">Received Card</h4>
        <v-card-title v-if="rowReceivedCard && receivedCardData">
          <v-img
            v-if="receivedCardData.screenshotPath"
            max-width="384"
            max-height="240"
            elevation="1"
            style="
              border-radius: 10px;
              border: 0.5px solid grey;
              margin-bottom: 20px;
            "
            :src="receivedCardData.screenshotPath"
          >
          </v-img>
          <v-list-item-title>{{receivedCardData.name}}</v-list-item-title>

          <v-list-item-subtitle
            class="text--primary"
          >
          {{
            `${receivedCardData.cardId} || ${
                receivedCardData.isPrivate ? 'Private' : 'Open'
              } || Chat Enable: ${receivedCardData.enableChat}`
          }}
          </v-list-item-subtitle>

          <v-list-item-subtitle>
            {{
              `${
                receivedCardData.designation
                  ? `${receivedCardData.designation} || `
                  : ''
              }${
                receivedCardData.emails ? receivedCardData.emails.join(',') : ''
              }`
            }}
          </v-list-item-subtitle>
        </v-card-title>
        <h4>Choose Card To Share</h4>
        <v-list two-line>
          <template v-if="myCards.length">
            <v-list-item-group
              v-model="selectShareCardOnReceivedCard"
              active-class="blue--text"
            >
              <template v-for="(card, index) in myCards">
                <v-list-item >
                  <template v-slot:default="{ active }">
                    <v-list-item-content :key="index">
                      <v-img
                        v-if="card.screenshotPath"
                        max-width="384"
                        max-height="240"
                        elevation="1"
                        style="
                          border-radius: 10px;
                          border: 0.5px solid grey;
                          margin-bottom: 20px;
                        "
                        :src="card.screenshotPath"
                      >
                      </v-img>
                      <v-list-item-title>{{card.name}}</v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                      >
                      {{
                        `${card.id} || ${
                            card.isPrivate ? 'Private' : 'Open'
                          } || Chat Enable: ${card.enableChat}`
                      }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle>
                        {{
                          `${
                            card.designation ? `${card.designation} || ` : ''
                          }${card.emails ? card.emails.join(',') : ''}`
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon v-if="active" x-small color="blue">
                        fa-check
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
            <v-divider />
          </template>
        </v-list>
        <v-progress-circular
          v-if="loading"
          class="my-10"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn
          v-else-if="
            selectShareCardOnReceivedCard !== null &&
            selectShareCardOnReceivedCard !== undefined
          "
          class="my-10"
          color="primary"
          @click="shareCardAction"
        >
          Share Card
        </v-btn>
      </v-card>
    </v-dialog>
  </v-card>
  <div v-else-if="getRole === null" class="row">
    <div class="col">You are not an Authorized user</div>
  </div>
  <div v-else class="row">
    <div class="col text-center">Authenticating...</div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { headers } from '../services/headers';
import { format } from 'date-fns';
import firebase from 'firebase/app';

export default {
  name: 'Collection',
  props: {
    activeTab: Object,
  },
  data() {
    return {
      handoverUserUID: null,
      myCards: [],
      selectedMyCard: null,
      cards: [],
      selectedCard: null,
      loading: false,
      shareCardOnReceivedCardDialog: false,
      rowReceivedCard: null,
      rowReceivedCardIndex: null,
      selectShareCardOnReceivedCard: null,
      receivedCardData: null,
    };
  },
  created() {
    this.fetchCards(this.$route.params.uid);
  },
  computed: {
    ...mapState({
      hradmin: (state) => state.hradmin,
    }),
    ...mapGetters([
      'getCollectionResults',
      'getRole',
      'getSearchText',
      'getSelectedUser',
      'getUsers',
      'getCurrentUser',
    ]),
    getHeaders() {
      const head = headers(this.activeTab.name, this.$route.params.uid);
      if (this.getCollectionResults?.length === 0) {
        return [];
      }
      return [...head];
    },
  },
  watch: {
    activeTab(t) {
      // this.$refs['collectionTable'].reset(true);
      this.fetchCollectionResults({
        name: t.name,
        condition: t.condition,
        value: t.value,
      });
    },
    handoverUserUID(val, oldVal) {
      if (val && val != oldVal) {
        this.fetchCards(val);
      }
    },
  },
  methods: {
    ...mapActions([
      'fetchCollectionResults',
      'fetchCard',
      'addShareCardOnReceiveCard',
    ]),
    handleClick(rowObj) {
      const path = `/user/${
        this.$route.params.uid
      }/${this.activeTab.name.toLowerCase()}/${rowObj.row.id}`;
      if (this.$route.fullPath != path) this.$router.push(path);
    },
    formatDate(value) {
      if (value.date) {
        return format(new Date(value.date), 'dd MMM yyyy');
      }
    },
    onSortChange(params) {
      console.log(params);
    },
    reset() {
      console.log('reset');
    },
    async transferAllData() {
      const data = {
        fromUser: this.getSelectedUser[0],
        fromCardId: this.myCards[this.selectedMyCard].id,
        toUser: this.getUsers.find((user) => user.uid == this.handoverUserUID),
        toCardId: this.cards[this.selectedCard].id,
      };
      console.log(data);
      this.loading = true;
      const httpsCallable = firebase
        .functions()
        .httpsCallable('transferDataFromUserToUser');
      const result = await httpsCallable(data);
      this.loading = false;
      alert(result.data);
      this.selectedMyCard = null;
      this.cards = [];
      this.selectedCard = null;
      this.handoverUserUID = null;
    },
    async fetchCards(uid) {
      try {
        if (uid == this.handoverUserUID) {
          this.cards = [];
          this.selectedCard = null;
        } else {
          this.myCards = [];
          this.selectedMyCard = null;
        }
        return new Promise(async (resolve) => {
          const snapshot = await firebase
            .firestore()
            .collection('cards')
            .where('userId', '==', uid)
            .get();

          const results = snapshot.docs.map((doc) => {
            const data = doc.data();
            data['id'] = doc.id;
            return data;
          });
          if (uid == this.handoverUserUID) {
            this.cards = results;
          } else {
            this.myCards = results;
          }


          resolve(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async shareCardAction() {
      this.loading = true;
      const receivedCard = this.rowReceivedCard;
      const sharedCard = this.myCards[this.selectShareCardOnReceivedCard];
      const requestData = {
        acceptOnCardId: receivedCard.cardId,
        createTime: new Date().toISOString(),
        dateTime: new Date().toISOString(),
        description: 'New Card Request',
        from: receivedCard.to.uid,
        fromName: receivedCard.to.name,
        read: false,
        to: receivedCard.from.uid,
        toName: receivedCard.from.name,
        cardData: sharedCard,
      };
      const httpsCallable = firebase.functions().httpsCallable('shareCard');
      const result = await httpsCallable(requestData);
      // const result = await this.addShareCardOnReceiveCard({
      //   receivedCard: this.rowReceivedCard,
      //   sharedCard: this.myCards[this.selectShareCardOnReceivedCard],
      // });
      if (result.data.status === 1) {
        this.getCollectionResults[this.rowReceivedCardIndex]['acceptOnCardId'] =
          [sharedCard.id];
      }
      this.loading = false;
      this.shareCardOnReceivedCardDialog = false;
      this.rowReceivedCard = null;
      this.rowReceivedCardIndex = null;
      this.selectShareCardOnReceivedCard = null;
      this.receivedCardData = null;
      alert(result.data.message);
    },
    async goToCreateCard() {
      console.log(this.$route.params.uid);
      this.$router.push({
        name: 'CreateCard',
        params: {
          data: {
            userId: this.$route.params.uid,
          },
        },
      });
    },
    editCard(props) {
      this.$router.push(`/edit-card/${props.row.id}`);
    },
  },
  mounted() {
    if (this.activeTab)
      this.fetchCollectionResults({
        name: this.activeTab.name,
        condition: this.activeTab.condition,
        value: this.activeTab.value,
      });
  },
};
</script>
<style lang="scss">
.min-h-100 {
  min-height: 100vh;
}
</style>
