<template>
  <v-navigation-drawer
    :temporary="value"
    fixed
    style="z-index: 4"
    v-if="activeTab"
  >
    <v-list nav style="padding-top: 5rem">
      <v-list-item-group active-class="deep-purple--text text--accent-4">
        <v-list-item>
          <v-list-item-subtitle class="list-header">
            <v-btn icon @click="$router.replace('/')">
              <v-icon small>fa-arrow-left</v-icon>
            </v-btn>
            Collections
          </v-list-item-subtitle>
        </v-list-item>
        <v-divider class="my-2" />
        <template v-for="(collection, index) in collections">
          <v-list-item
            :key="'collection_' + index"
            @click="$emit('setActiveTab', collection)"
          >
            <v-list-item-title
              class="list-item"
              :class="
                activeTab.name === collection.name
                  ? 'black--text'
                  : 'grey--text'
              "
              >{{ collection.name }}</v-list-item-title
            >
          </v-list-item>
          <v-divider class="my-2" :key="'divider_' + index" />
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "Sidebar",
  props: {
    value: Boolean,
    collections: Array,
    activeTab: Object,
  },
};
</script>
<style>
.list-header {
  text-transform: uppercase;
  color: #aaa;
  font-size: 0.75rem;
}

</style>