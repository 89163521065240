<template>
  <v-app>
    <v-main v-if="getRole" style="padding-top: 0">
      <v-container fluid>
        <v-row>
          <v-col
            :style="{ maxWidth: !getDrawer ? '256px' : 0 }"
            :class="{ 'px-0': getDrawer }"
          >
            <sidebar
              :value="getDrawer"
              @setActiveTab="setActiveTab"
              :collections="collections"
              :activeTab="tab"
            />
          </v-col>
          <v-col :style="{ maxWidth: !getDrawer ? 'calc(100% - 256px)' : 0 }">
            <v-row>
              <v-col :cols="$route.params.doc ? 6 : 12">
                <collection :admin="getRole" :activeTab="tab" />
              </v-col>
              <v-col v-if="$route.params.doc" cols="6">
                <router-view />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-main v-else>
      <div class="row">
        <div class="col">You are not an Authorized user</div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import Collection from '../components/Collection.vue';
import Sidebar from '../components/Sidebar';
import { format } from 'date-fns';

export default {
  name: 'User',
  computed: {
    ...mapGetters(['getRole', 'getDrawer', 'getUsers']),
    ...mapState({
      hradmin: (state) => state.hradmin,
    }),
  },
  data() {
    return {
      tab: null,
      collections: [
        {
          name: 'cards',
          condition: 'userId',
          value: this.$route.params?.uid,
        },
        {
          name: 'received',
          condition: 'to.uid',
          value: this.$route.params?.uid,
        },
        {
          name: 'pending',
          condition: 'to.uid',
          value: this.$route.params?.uid,
        },
        {
          name: 'shared',
          condition: 'from.uid',
          value: this.$route.params?.uid,
        },
        {
          name: 'status',
          condition: 'userId',
          value: this.$route.params?.uid,
        },
        {
          name: 'permissions',
          condition: 'fromUser.uid',
          value: this.$route.params?.uid,
        },
        {
          name: 'requests',
          condition: 'from',
          value: this.$route.params?.uid,
        },
        {
          name: 'chatrooms',
          condition: this.$route.params.uid + '.profile.uid',
          value: this.$route.params?.uid,
        },
        {
          name: 'chats',
          condition: 'toUID',
          value: this.$route.params?.uid,
        },
        ...(this.getRole?.id == this.hradmin
          ? [
              {
                name: 'handover',
              },
            ]
          : []),
      ],
    };
  },
  watch: {
    getRole(r) {
      if (r?.id == null) {
        this.$router.replace('/');
      }
    },
  },
  components: { Sidebar, Collection },
  methods: {
    ...mapActions(['fetchUsers']),
    ...mapMutations(['setSelectedUser']),
    setActiveTab(tab) {
      this.tab = tab;
      if (this.$route.params.doc) {
        this.$router.replace(`/user/${this.$route.params.uid}`);
      }
    },
    formatDate(value) {
      if (value.createDate) {
        return format(new Date(value.createDate), 'dd MMM yyyy hh:mm a');
      }
    },
  },
  async mounted() {
    await this.fetchUsers();
    const selectedUser = this.getUsers.filter((u) => {
      return u.uid == this.$route.params.uid;
    });

    this.$store.commit('setSelectedUser', selectedUser);

    if (this.collections.length) this.tab = this.collections[0];
    if (!this.getRole) {
      this.$router.replace('/');
    }
  },
};
</script>
