export function headers(type, uid) {
  switch (type) {
    case 'Users':
    case 'users':
      return [
        { label: 'Name', align: 'start', sortable: false, field: 'name' },
        { label: 'UserId', align: 'start', sortable: false, field: 'uid' },
        {
          label: 'CareateDate',
          align: 'start',
          sortable: false,
          field: 'createDate',
        },
        { label: 'Email', field: 'email', sortable: false },
        { label: 'Phone', field: 'phone', sortable: false },
        { label: 'Identifier', field: 'identifier', sortable: false },
        { label: 'Provider', field: 'provider', sortable: false },
        { label: 'Visicardia Handle', field: 'handle', sortable: false },
      ];
    case 'Cards':
    case 'cards':
      return [
        { label: 'Name', align: 'start', sortable: true, field: 'name' },
        { label: 'Chat', align: 'start', sortable: true, field: 'enableChat' },
        {
          label: 'Is Private',
          align: 'start',
          sortable: true,
          field: 'isPrivate',
        },
        { label: 'Card Id', align: 'start', sortable: true, field: 'id' },
        { label: 'Email', align: 'start', sortable: true, field: 'emails' },
        { label: 'Tags', align: 'start', sortable: true, field: 'tags' },
        {
          label: 'Address',
          align: 'start',
          sortable: true,
          field: 'address',
          width: '300px',
        },
        {
          label: 'Phone',
          align: 'start',
          sortable: true,
          field: 'phoneNumbers',
        },
        {
          label: 'Actions',
          field: 'card_action',
          sortable: false,
          width: '80px',
        },
      ];
    case 'Received':
    case 'received':
      return [
        { label: 'Id', align: 'start', sortable: true, field: 'id' },
        { label: 'Card Id', align: 'start', sortable: true, field: 'cardId' },
        { label: 'From', align: 'start', sortable: true, field: 'from.name' },
        {
          label: 'From UID',
          align: 'start',
          sortable: true,
          field: 'from.uid',
        },
        {
          label: 'From Email',
          align: 'start',
          sortable: true,
          field: 'from.email',
        },
        {
          label: 'Email Ver.',
          align: 'start',
          sortable: true,
          field: 'from.emailVerified',
        },
        { label: 'Pending', align: 'start', sortable: true, field: 'pending' },
        {
          label: 'Handle',
          align: 'start',
          sortable: true,
          field: 'from.handle',
        },
        {
          label: 'Chat Not.',
          align: 'start',
          sortable: true,
          field: 'from.chatNotificationEnabled',
        },
        {
          label: 'Gen. Not.',
          align: 'start',
          sortable: true,
          field: 'from.generalNotificationEnabled',
        },
        {
          label: 'shared card',
          align: 'start',
          field: 'shared_card',
        },
      ];
    case 'Pending':
    case 'pending':
      return [
        { label: 'From', align: 'start', sortable: true, field: 'from.name' },
        {
          label: 'From UID',
          align: 'start',
          sortable: true,
          field: 'from.uid',
        },
        {
          label: 'From Email',
          align: 'start',
          sortable: true,
          field: 'from.email',
        },
        { label: 'To', align: 'start', sortable: true, field: 'to.name' },
        { label: 'To UID', align: 'start', sortable: true, field: 'to.uid' },
        {
          label: 'To Email',
          align: 'start',
          sortable: true,
          field: 'to.email',
        },

        { label: 'Pending', align: 'start', sortable: true, field: 'pending' },
        {
          label: 'Tags',
          align: 'start',
          sortable: true,
          field: 'cardData.tags',
        },
        {
          label: 'Handle',
          align: 'start',
          sortable: true,
          field: 'from.handle',
        },
      ];
    case 'Shared':
    case 'shared':
      return [
        { label: 'Id', align: 'start', sortable: true, field: 'id' },
        { label: 'Card Id', align: 'start', sortable: true, field: 'cardId' },
        { label: 'To', align: 'start', sortable: true, field: 'to.name' },
        { label: 'To UID', align: 'start', sortable: true, field: 'to.uid' },
        {
          label: 'To Email',
          align: 'start',
          sortable: true,
          field: 'to.email',
        },
        { label: 'Pending', align: 'start', sortable: true, field: 'pending' },
        {
          label: 'Handle',
          align: 'start',
          sortable: true,
          field: 'from.handle',
        },
      ];
    case 'Permissions':
    case 'permissions':
      return [
        {
          label: 'Card Owner',
          align: 'start',
          sortable: true,
          field: 'cardOwner.name',
        },
        {
          label: 'Sending To',
          align: 'start',
          sortable: true,
          field: 'sendingTo.name',
        },
        { label: 'Pending', align: 'start', sortable: true, field: 'pending' },
      ];
    case 'Requests':
    case 'requests':
      return [
        {
          label: 'From User',
          align: 'start',
          sortable: true,
          field: 'fromName',
        },
        { label: 'To User', align: 'start', sortable: true, field: 'toName' },
        { label: 'Read', align: 'start', sortable: true, field: 'read' },
        { label: 'Intro', align: 'start', sortable: true, field: 'intro' },
      ];
    case 'Invites':
    case 'invites':
      return [
        { label: 'CardId', align: 'start', sortable: true, field: 'cardIds' },
        { label: 'Contact', align: 'start', sortable: true, field: 'contact' },
        { label: 'Inviter', align: 'start', sortable: true, field: 'inviter' },
      ];
    case 'Status':
    case 'status':
      return [
        { label: 'StatusId', align: 'start', sortable: true, field: 'id' },
        { label: 'UserId', align: 'start', sortable: true, field: 'userId' },
        {
          label: 'FileName',
          align: 'start',
          sortable: true,
          field: 'fileName',
        },
        {
          label: 'StartDate',
          align: 'start',
          sortable: true,
          field: 'statusDateRange.start',
        },
        {
          label: 'EndDate',
          align: 'start',
          sortable: true,
          field: 'statusDateRange.end',
        },
      ];
    case 'Chatrooms':
    case 'chatrooms':
      return [
        { label: 'ChatroomId', align: 'start', sortable: true, field: 'id' },
        {
          label: 'UserId',
          align: 'start',
          sortable: true,
          field: uid + '.profile.uid',
        },
        {
          label: 'PartnerId',
          align: 'start',
          sortable: true,
          field: uid + '.partnerId',
        },
        {
          label: 'EnableChat',
          align: 'start',
          sortable: true,
          field: uid + '.enableChat',
        },
        {
          label: 'Recd Card Id',
          align: 'start',
          sortable: true,
          field: uid + '.recdCardId',
        },
      ];
    case 'Chats':
    case 'chats':
      return [
        { label: 'ChatId', align: 'start', sortable: true, field: 'id' },
        {
          label: 'ChatRoomId',
          align: 'start',
          sortable: true,
          field: 'chatRoomId',
        },
        { label: 'From', align: 'start', sortable: true, field: 'fromUID' },
        { label: 'Seen', align: 'start', sortable: true, field: 'seen' },
        {
          label: 'Deleted',
          align: 'start',
          sortable: true,
          field: 'deletedFor',
        },
        { label: 'Date', align: 'start', sortable: true, field: 'date' },
        { label: 'To', align: 'start', sortable: true, field: 'toUID' },
      ];
  }
}
